const theme = {
  fonts: {
    secondary: 'HelveticaNeue',
    main: 'SangBleuOGSerif',
  },
  colors: {
    marble: '#efede4',
    red: '#ff5945',
    grey: '#999999',
    blue: '#337896',
    green: '#758073',
  },
  shadow: '',
  breakpoints: {
    size: {
      mobileS: '320px',
      mobileM: '375px',
      mobileL: '425px',
      tablet: '768px',
      laptop: '1024px',
      laptopL: '1440px',
      desktop: '1800px',
      desktopL: '2560px',
    },
  },
};

export default theme;
